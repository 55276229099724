import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { TruncatePipe } from 'app/pipes/truncate.pipe';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IPresetImage, IPresetImages } from 'app/interfaces/image-generation.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';

import { IMAGE_PRESETS } from 'app/shared/const/image-presets.data';

@Component({
  selector: 'stxt-image-presets',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, TruncatePipe],
  templateUrl: './image-presets.component.html',
  styleUrl: './image-presets.component.scss'
})
export class ImagePresetsComponent {
  @Output() selectedPresetPrompt: EventEmitter<IPresetImage> = new EventEmitter<IPresetImage>();
  presetList: string[] = ['Scene', 'Activity', 'Outfit', 'Accessories', 'Pose'];
  selectedPreset: string = 'Scene';
  addIcon: ISvgConfig = { fill: ESvgTypes.Filled, name: 'add' };
  doneIcon: ISvgConfig = { fill: ESvgTypes.Filled, name: 'done' };
  selectedImages: Set<string> = new Set();
  images: IPresetImages = IMAGE_PRESETS;

  onPresetClick(preset: string): void {
    this.selectedPreset = preset;
  }

  onImageSelect(imageUrl: string): void {
    const existingImageForCategory = Array.from(this.selectedImages).find(url => {
      const image = this.images[this.selectedPreset].find(img => img.image === url);
      return image !== undefined;
    });

    if (existingImageForCategory) {
      this.selectedImages.delete(existingImageForCategory);
    }

    const selectedImage = this.images[this.selectedPreset].find(img => img.image === imageUrl);
    if (selectedImage) {
      this.selectedImages.add(imageUrl);
      this.selectedPresetPrompt.emit(selectedImage);

      const currentIndex = this.presetList.indexOf(this.selectedPreset);
      if (currentIndex < this.presetList.length - 1) {
        setTimeout(() => {
          this.selectedPreset = this.presetList[currentIndex + 1];
        }, 1000);
      }
    }
  }
}
