<div
  *ngIf="paymentType === EPaymentType.Billing"
  class="flex flex-row items-center space-between add-payment-method_billing"
>
  <p class="add-payment-method_title">Payment method</p>
  <div class="flex add-payment-method_cards__wrapper">
    <ng-container *ngFor="let paymentMethod of availablePayments">
      <img
        class="add-payment-method_card"
        src="/assets/d2c-images/{{ paymentMethod }}.svg"
        alt="{{ paymentMethod }}"
      />
    </ng-container>
  </div>
</div>

<div class="flex flex-column pb-2">
  <div
    *ngIf="paymentType === EPaymentType.Token || paymentType === EPaymentType.Subscription"
    class="flex flex-row items-center space-between"
  >
    <p class="add-payment-method_title">Payment method</p>
    <div class="flex add-payment-method_cards__wrapper">
      <ng-container *ngFor="let paymentMethod of availablePayments">
        <img
          class="add-payment-method_card"
          src="/assets/d2c-images/{{ paymentMethod }}.svg"
          alt="{{ paymentMethod }}"
        />
      </ng-container>
    </div>
  </div>

  <div class="payment-form">
    <input
      placeholder="First name"
      class="card-field w-full payment_input"
      [ngClass]="{ error: cardFieldErrors['firstName'] }"
      (blur)="validateField('firstName', $event.target.value)"
      (input)="validateField('firstName', $event.target.value)"
      type="text"
      data-opid="firstName"
    />
    <input
      placeholder="Last name"
      class="card-field w-full"
      type="text"
      (blur)="validateField('lastName', $event.target.value)"
      (input)="validateField('lastName', $event.target.value)"
      [ngClass]="{ error: cardFieldErrors['lastName'] }"
      data-opid="lastName"
    />
    <div
      id="card-number-element"
      class="card-field p-0"
      [ngClass]="{ error: cardFieldErrors['cardNumber'] }"
    ></div>
    <div class="payment-row">
      <div
        id="card-expiry-element"
        class="card-field p-0"
        [ngClass]="{ error: cardFieldErrors['cardExpiry'] }"
      ></div>
      <div
        id="card-cvc-element"
        class="card-field p-0"
        [ngClass]="{ error: cardFieldErrors['cardCvc'] }"
      ></div>
    </div>
    <input
      placeholder="ZIP or postal code"
      class="card-field w-full"
      type="text"
      [ngClass]="{ error: cardFieldErrors['zipCode'] }"
      (blur)="validateField('zipCode', $event.target.value)"
      (input)="validateField('zipCode', $event.target.value)"
      data-opid="zipCode"
    />
    <input
      hidden="hidden"
      placeholder="email"
      class="card-field w-full"
      type="text"
      value="{{ user.username }}"
      data-opid="email"
    />
    <div class="d-inline-block w-full" ngbDropdown #myDrop="ngbDropdown">
      <button
        #dropdownButton
        data-opid="country"
        [value]="selectedCountry?.code"
        type="button"
        class="country-select flex space-between items-center"
        [ngClass]="{ error: cardFieldErrors['country'] }"
        [ngStyle]="{ color: selectedCountry?.code ? '#ddc9de' : '#8c768e' }"
        id="dropdownManual"
        ngbDropdownAnchor
        (click)="myDrop.toggle()"
        (keydown)="handleKeyboardNavigation($event)"
      >
        {{ selectedCountry?.name || 'Select country' }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownManual">
        <button
          *ngFor="let country of countries; let i = index"
          ngbDropdownItem
          (click)="setValue(country)"
          [id]="'country-' + i"
          [ngClass]="{
            highlighted: i === highlightedCountryIndex,
            selected: country.code === selectedCountry?.code
          }"
        >
          {{ country.name }}
        </button>
      </div>
    </div>
  </div>

  <!-- TODO: For the moment we don't need to set default payment method,
   as we're not sure if there gonna be more than one -->
  <!-- <div *ngIf="!firstCheckOut" class="add-payment-method_checkbox">
    <input
      class="form-check-input"
      type="checkbox"
      id="flexCheckDefault">
    <p>
      Set as default payment method
    </p>
  </div> -->
</div>
<ng-container *ngIf="paymentType === EPaymentType.Billing">
  <stxt-button
    [buttonConfig]="addBtnConfig"
    (keydown.enter)="addBillingInfo()"
    (click)="addBillingInfo()"
    class="new-payment_btn"
  >
    {{ addBtnConfig.text }}
  </stxt-button>
</ng-container>

<ng-container *ngIf="showError">
  <stxt-payment-failed [paymentType]="paymentType" [error]="errorMessages"></stxt-payment-failed>
</ng-container>
