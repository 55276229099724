import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | undefined, limit: number = 8): string {
    if (!value) return '';

    if (value.length > limit) {
      return value.substring(0, limit) + '...';
    }
    return value;
  }
}
