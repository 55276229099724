import { Component } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ISvgConfig } from '../../interfaces/svg.interfaces';
import { ESvgTypes } from '../../enums/svg.enums';

@Component({
  selector: 'stxt-notification-badge',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './notification-badge.component.html',
  styleUrl: './notification-badge.component.scss'
})
export class NotificationBadgeComponent {
  notificationSvg: ISvgConfig = {name: 'notification-badge', fill: ESvgTypes.None};
}
