<div class="notifications">
    <h4 class="notifications_heading">Notifications</h4>
    <p class="notifications_subheading">Let us know what you’d like to be notified about by email</p>
        <div class="notifications_content_item">
            <div class="notifications_content_item__header">
                <p class="notifications_content_item__header___title">Messages</p>
                <p class="notifications_content_item__header___subtitle">Let you know when you’ve received messages</p>
            </div>
                <stxt-svg-icon class="notification_arrow-svg" [iconConfig]="arrowSvgConfig"></stxt-svg-icon>
        </div>
        <div class="notifications_content_item">
            <div class="notifications_content_item__header">
                <p class="notifications_content_item__header___title">Message reminders</p>
                <p class="notifications_content_item__header___subtitle">Let you know when you’ve received messages you have yet to read</p>
            </div>
                <stxt-svg-icon class="notification_arrow-svg" [iconConfig]="arrowSvgConfig"></stxt-svg-icon>
        </div>
        <div class="notifications_content_item">
            <div class="notifications_content_item__header">
                <p class="notifications_content_item__header___title">Updates</p>
                <p class="notifications_content_item__header___subtitle">Sent to your email</p>
            </div>
                <stxt-toggle></stxt-toggle>
        </div>
        <div class="notifications_content_item">
            <div class="notifications_content_item__header">
                <p class="notifications_content_item__header___title">Offers</p>
                <p class="notifications_content_item__header___subtitle">Sent to your email</p>
            </div>
                <stxt-toggle></stxt-toggle>
        </div>      
        <div class="notifications_content_item">
            <div class="notifications_content_item__header">
                <p class="notifications_content_item__header___title">Payment</p>
            </div>
            <stxt-svg-icon class="notification_arrow-svg" [iconConfig]="arrowSvgConfig"></stxt-svg-icon>
        </div>  
</div>