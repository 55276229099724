import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { catchError, concatMap, debounceTime, first, of, Subject, Subscription } from 'rxjs';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { OpenPayForm } from '@getopenpay/openpay-js';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { PaymentsService } from 'app/shared/services/payments.service';
import { ProfileService } from 'app/shared/services/profile.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { TokensService } from '../../../shared/services/tokens.service';

import { InputComponent } from 'app/shared/components/input/input.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';
import { PaymentFailedComponent } from '../payment-failed/payment-failed.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import {
  IPaymentErrorMessage,
  IPaymentResult,
  IPurchaseData,
  ISubscriptionData
} from 'app/interfaces/payments.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { IBillingPostResponse } from 'app/interfaces/profile.interfaces';
import { ITokenPlan } from '../../../shared/interfaces/token.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import {
  EPaymentErrorMessage,
  EPaymentErrorTitle,
  EPaymentField,
  EPaymentStatus,
  EPaymentType
} from 'app/enums/payments.enums';
import { EButtonTypes, EButtonSizes } from 'app/shared/enums/button.enums';
import { EProfileState } from 'app/enums/profile.enums';
import { ELocalStorageKey } from 'app/shared/enums/shared.enums';

import { COUNTRIES, ICountry } from '../../../shared/const/countries';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'stxt-payment-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    CreditCardDirectivesModule,
    InputComponent,
    SvgIconComponent,
    ButtonComponent,
    PaymentFailedComponent
  ],
  templateUrl: './payment-form.component.html',
  styleUrl: './payment-form.component.scss'
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  @Input() firstCheckOut: boolean;
  @Input() creator: string;
  @Input() creatorId: string;
  @Input() subscriptionData?: ISubscriptionData;
  @Input() purchaseData?: IPurchaseData;
  @Input() tokenData?: ITokenPlan;
  @Input() paymentType: string;
  @Input() wsConnectionId: string;
  @Output() paymentSuccessEvent: EventEmitter<IPaymentResult> = new EventEmitter<IPaymentResult>();
  @Output() formValidityChange = new EventEmitter<boolean>();
  @Output() paymentFinishEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('myDrop') myDrop!: NgbDropdown;

  public addBtnConfig: IButtonConfig = {
    text: 'Add payment method',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  public availablePayments: string[] = ['Visa', 'Mastercard'];
  public paymentsSubscription: Subscription;
  public formValiditySubscription: Subscription;
  public selectedCountry?: ICountry;
  public errorIcon: ISvgConfig = { name: 'error', fill: ESvgTypes.None };
  public checkoutSecureToken: string;
  public EPaymentType = EPaymentType;
  public user = JSON.parse(localStorage.getItem('user'));
  public isError: boolean = false;
  public showError: boolean = false;
  public EPaymentField = EPaymentField;
  public EPaymentErrorTitle = EPaymentErrorTitle;
  public EPaymentErrorMessage = EPaymentErrorMessage;
  public errorMessages: IPaymentErrorMessage;
  public cardFieldErrors: { [key: string]: boolean } = {
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    firstName: false,
    lastName: false,
    zipCode: false,
    country: false
  };
  private readonly searchTermSubject = new Subject<string>();
  private searchTerm: string = '';

  openPayForm: OpenPayForm;
  isStagingEnv: boolean;
  countries = COUNTRIES;
  fieldErrorStates: { [key: string]: boolean } = {};
  userId = localStorage.getItem(ELocalStorageKey.UserId);
  highlightedCountryIndex: number | null = null;

  constructor(
    private readonly paymentsService: PaymentsService,
    private readonly profileService: ProfileService,
    private readonly analyticsService: AnalyticsService,
    private readonly tokensService: TokensService
  ) {}

  ngOnInit(): void {
    if (
      environment.serviceEnvironment === 'staging' ||
      environment.serviceEnvironment === 'development'
    ) {
      this.isStagingEnv = true;
    }
    this.subscribeOnPaymentsEvent();
    this.profileService
      .generateSecureToken()
      .pipe(first())
      .subscribe({
        next: (res: IBillingPostResponse) => {
          this.checkoutSecureToken = res.secure_token;
          this.initOpenpayForm();
        },
        error: (err: Error) => {
          console.log(err);
        }
      });
    this.handleCountrySearch();
  }

  initOpenpayForm(): void {
    this.openPayForm = new OpenPayForm({
      baseUrl: this.isStagingEnv ? 'https://cde.openpaystaging.com' : undefined,
      checkoutSecureToken: this.checkoutSecureToken,
      formTarget: '#payment-form',
      onValidationError: () => {},

      onSetupPaymentMethodSuccess: (paymentMethodId: string) => {
        this.paymentTypeCheck(paymentMethodId);
      },
      onCheckoutError: () => this.onCheckoutError(),
      onChange: (elementId: string, field: string, errors: string[]) =>
        this.onChange(field, errors),
      onBlur: (elementId: string, field: string) => this.onBlur(elementId, field)
    });
    const styles = {
      color: '#DDC9DE',
      fontSize: '14px',
      fontFamily: 'sans-serif',
      fontWeight: '400',
      backgroundColor: 'transparent',
      borderRadius: '12px',
      padding: '10px 16px',
      placeholderStyle: {
        color: '#8c768e',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'sans-serif',
        letterSpacing: '-0.28px',
        lineHeight: '20px'
      }
    };

    this.openPayForm
      .createElement('card-number', {
        styles: { ...styles, placeholder: 'Credit card' }
      })
      .mount('#card-number-element');

    this.openPayForm
      .createElement('card-expiry', {
        styles: { ...styles, placeholder: 'Expiry Date (MM/YY)' }
      })
      .mount('#card-expiry-element');

    this.openPayForm
      .createElement('card-cvc', {
        styles: { ...styles, placeholder: 'Security code' }
      })
      .mount('#card-cvc-element');
    this.isError = true;
  }

  onCheckoutError(): void {
    this.isError = true;
    this.showError = true;
    this.errorMessages = {
      errorType: EPaymentField.generic,
      errorTitle: EPaymentErrorTitle.generic,
      errorMessage: EPaymentErrorMessage.generic
    };
    this.paymentFinishEvent.emit();
  }

  setValue(value: ICountry): void {
    this.selectedCountry = value;
    this.cardFieldErrors['country'] = false;
  }

  onBlur(elementId: string, field: string): void {
    if (Object.keys(this.cardFieldErrors).includes(field)) {
      this.cardFieldErrors[field] = this.fieldErrorStates[field] || false;
    }
    this.isError = Object.values(this.fieldErrorStates).some(state => state === true);
    this.formValidityChange.emit(this.isError);
  }

  onChange(field: string, errors: string[]): void {
    this.fieldErrorStates[field] = errors.length > 0;
    if (Object.keys(this.cardFieldErrors).includes(field)) {
      this.cardFieldErrors[field] = errors.length > 0;
    }
  }

  addBillingInfo(): void {
    this.markErrorFields();
    if (this.checkErrors()) return;
    this.openPayForm.submit();
  }

  markErrorFields(): void {
    if (this.isError) {
      Object.keys(this.cardFieldErrors).forEach(field => {
        this.cardFieldErrors[field] = true;
      });
    }
  }

  private checkErrors(): boolean {
    if (this.isError) {
      this.showError = false;
      this.errorMessages = {
        errorType: EPaymentField.generic,
        errorTitle: EPaymentErrorTitle.generic,
        errorMessage: EPaymentErrorMessage.generic
      };
      return true;
    }

    this.showError = false;
    return false;
  }

  handleCountrySearch(): void {
    this.searchTermSubject.pipe(debounceTime(1500)).subscribe(() => {
      this.searchTerm = '';
    });
  }

  handleKeyboardNavigation(event: KeyboardEvent): void {
    if (!/^[a-zA-Z]$/.test(event.key)) return;

    event.preventDefault();
    !this.myDrop.isOpen() && this.myDrop.open();

    this.searchTerm += event.key.toLowerCase();
    const countryIndex = this.countries.findIndex(country =>
      country.name.toLowerCase().startsWith(this.searchTerm)
    );

    if (countryIndex >= 0) {
      this.highlightedCountryIndex = countryIndex;
      document.getElementById(`country-${countryIndex}`)?.scrollIntoView({ block: 'center' });
      this.setValue(this.countries[countryIndex]);
    }

    this.searchTermSubject.next(this.searchTerm);
  }

  validateField(field: string, value: string): void {
    const trimmedValue = value?.trim();
    const hasError =
      ['firstName', 'lastName', 'zipCode', 'country'].includes(field) && !trimmedValue;

    this.cardFieldErrors[field] = hasError;
    this.fieldErrorStates[field] = hasError;
    this.isError = Object.values(this.fieldErrorStates).some(state => state === true);
    this.formValidityChange.emit(this.isError);
  }

  subscribeOnPaymentsEvent(): void {
    this.paymentsSubscription = this.paymentsService.$submitPayment.subscribe({
      next: value => {
        if (!value) return;
        this.addBillingInfo();
      },
      error: error => {
        console.log(error);
      }
    });
  }

  paymentTypeCheck(id: string): void {
    switch (this.paymentType) {
      case EPaymentType.Subscription:
        this.subscriptionPayment(id);
        break;
      case EPaymentType.Purchase:
        this.purchasePayment(id);
        break;
      case EPaymentType.Token:
        this.tokenPurchase(id);
        break;
      case EPaymentType.Billing:
        this.paymentSuccessEvent.emit({
          status: EPaymentStatus.Success,
          paymentType: EPaymentType.Billing
        });
        this.analyticsService.onboardingProfile(
          EProfileState.PROFILE_BILLING_INFO,
          this.userId,
          null,
          null
        );
        break;
    }
  }

  tokenPurchase(id: string): void {
    this.tokensService
      .purchaseTokens({
        billing_token: id,
        tokens_pack_id: this.tokenData.id,
        quantity: 1
      })
      .pipe(
        first(),
        concatMap(() =>
          this.tokensService.fetchTokensBalance(true).pipe(
            catchError(error => {
              console.error('Error fetching token balance:', error);
              return of(null);
            })
          )
        )
      )
      .subscribe({
        next: () => {
          this.initResultEvent(EPaymentStatus.Success, EPaymentType.Token);
        },
        error: err => {
          if (err.status === 200) {
            this.initResultEvent(EPaymentStatus.Success, EPaymentType.Token);
          } else {
            this.markErrorFields();
            this.initResultEvent(EPaymentStatus.Error, EPaymentType.Token, err.error.error_message);
          }
        }
      });
  }

  subscriptionPayment(id: string): void {
    this.paymentsService
      .creatorSubscriptionRequest({
        creator_id: this.creatorId,
        billing_token: id,
        plan_code: this.subscriptionData.code,
        ws_conn_id: this.wsConnectionId
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.initResultEvent(EPaymentStatus.Success, EPaymentType.Subscription);
        },
        error: err => {
          if (err.status === 200) {
            this.initResultEvent(EPaymentStatus.Success, EPaymentType.Subscription);
          } else {
            this.markErrorFields();
            this.initResultEvent(
              EPaymentStatus.Error,
              EPaymentType.Subscription,
              err.error.error_message
            );
          }
        }
      });
  }

  purchasePayment(id: string): void {
    this.paymentsService
      .purchaseMediaRequest({
        creator_id: this.creatorId,
        billing_token: id,
        message_timestamp: Number(this.purchaseData.message_timestamp),
        ws_conn_id: this.wsConnectionId
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.initResultEvent(EPaymentStatus.Success, EPaymentType.Purchase);
        },
        error: err => {
          if (err.status === 200) {
            this.initResultEvent(EPaymentStatus.Success, EPaymentType.Purchase);
          } else {
            this.markErrorFields();
            this.initResultEvent(
              EPaymentStatus.Error,
              EPaymentType.Purchase,
              err.error.error_message
            );
          }
        }
      });
  }

  initResultEvent(
    status: EPaymentStatus,
    paymentType: EPaymentType | string,
    error?: string
  ): void {
    const eventPayload: IPaymentResult = {
      status: status,
      paymentType: paymentType
    };

    if (error) {
      eventPayload.error_message = error;
    }

    this.paymentSuccessEvent.emit(eventPayload);
  }

  toUpperCase($event: Event) {
    const input = $event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }

  ngOnDestroy(): void {
    this.paymentsSubscription?.unsubscribe();
    this.formValiditySubscription?.unsubscribe();
    this.searchTermSubject.complete();
  }
}
