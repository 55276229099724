import { IPresetImages } from 'app/interfaces/image-generation.interfaces';

// Helper function to generate a preset object
const createPreset = (label: string, image: string, sceneDescription: string) => ({
  label,
  image,
  prompt: `${sceneDescription}`
});

export const IMAGE_PRESETS: IPresetImages = {
  Scene: [
    createPreset(
      'Garden',
      'assets/image-generation-presets/garden.webp',
      'set in a lush garden with vibrant flowers'
    ),
    createPreset(
      'Beach',
      'assets/image-generation-presets/beach.webp',
      'on a tranquil beach with soft sand and blue skies'
    ),
    createPreset(
      'Bedroom',
      'assets/image-generation-presets/bedroom.webp',
      'in a cozy bedroom with warm lighting'
    ),
    createPreset(
      'Gym',
      'assets/image-generation-presets/gym.webp',
      'in a modern gym, surrounded by equipment'
    ),
    createPreset(
      'Bar',
      'assets/image-generation-presets/bar.webp',
      'at a stylish bar with ambient lighting'
    ),
    createPreset(
      'Kitchen',
      'assets/image-generation-presets/kitchen.webp',
      'in a well-designed kitchen with sleek decor'
    ),
    createPreset(
      'Restaurant',
      'assets/image-generation-presets/restaurant.webp',
      'in an elegant restaurant with intimate lighting'
    ),
    createPreset(
      'Balcony',
      'assets/image-generation-presets/balcony.webp',
      'on a scenic balcony with a beautiful view'
    ),
    createPreset('Bathub', 'assets/image-generation-presets/bathub.webp', 'in a luxurious bathtub'),
    createPreset(
      'Street',
      'assets/image-generation-presets/street.webp',
      'on a lively city street with bustling energy'
    )
  ],
  Activity: [
    createPreset(
      'Working out',
      'assets/image-generation-presets/working-out.webp',
      'captured mid-workout, active stance'
    ),
    createPreset(
      'Dining',
      'assets/image-generation-presets/dining.webp',
      'enjoying a relaxed dining experience at a table'
    ),
    createPreset(
      'Tanning',
      'assets/image-generation-presets/taning.webp',
      'relaxing and tanning under the warm sunlight'
    ),
    createPreset(
      'Walking',
      'assets/image-generation-presets/walking.webp',
      'walking casually with a natural stride'
    )
  ],
  Pose: [
    createPreset(
      'Standing',
      'assets/image-generation-presets/standing.webp',
      'standing confidently with a steady posture'
    ),
    createPreset(
      'Sitting',
      'assets/image-generation-presets/sitting.webp',
      'sitting comfortably in a relaxed posture'
    ),
    createPreset(
      'Squatting',
      'assets/image-generation-presets/squatting.webp',
      'squatting with a balanced and composed posture'
    ),
    createPreset(
      'Stretching',
      'assets/image-generation-presets/stretching.webp',
      'stretching gracefully, showing flexibility'
    ),
    createPreset(
      'Kneeling',
      'assets/image-generation-presets/kneeling.webp',
      'kneeling with a balanced and composed posture'
    )
  ],

  Outfit: [
    createPreset('Bikini', 'assets/image-generation-presets/bikini.webp', 'In a stunning bikini.'),
    createPreset(
      'Skirt',
      'assets/image-generation-presets/skirt.webp',
      'wearing a stylish long skirt'
    ),
    createPreset(
      'Lingerie',
      'assets/image-generation-presets/lingerie.webp',
      'wearing elegant, refined lingerie'
    ),
    createPreset(
      'Crop top',
      'assets/image-generation-presets/crop-top.webp',
      'wearing a trendy crop top'
    ),
    createPreset(
      'Leather',
      'assets/image-generation-presets/leather.webp',
      'wearing a sleek bustier leather dress'
    ),
    createPreset(
      'Mini-skirt',
      'assets/image-generation-presets/mini-skirt.webp',
      'wearing a playful mini-skirt'
    ),
    createPreset(
      'Satin robe',
      'assets/image-generation-presets/satin-robe.webp',
      'wearing a satin robe'
    ),
    createPreset(
      'Jeans',
      'assets/image-generation-presets/jeans.webp',
      'wearing a pair of stylish jeans'
    ),
    createPreset(
      'Jumpsuit',
      'assets/image-generation-presets/jumpsuit.webp',
      'wearing a fitted catsuit'
    ),
    createPreset(
      'Sundress',
      'assets/image-generation-presets/sundress.webp',
      'wearing a light floral sundress'
    )
  ],
  Accessories: [
    createPreset(
      'Necklace',
      'assets/image-generation-presets/necklace.webp',
      'wearing a delicate necklace'
    ),
    createPreset(
      'Earrings',
      'assets/image-generation-presets/earings.webp',
      'wearing elegant earrings'
    ),
    createPreset(
      'Glasses',
      'assets/image-generation-presets/glasses.webp',
      'wearing stylish glasses'
    ),
    createPreset('Choker', 'assets/image-generation-presets/choker.webp', 'wearing a chic choker'),
    createPreset('Hat', 'assets/image-generation-presets/hat.webp', 'wearing a fashionable hat'),
    createPreset(
      'Sunglasses',
      'assets/image-generation-presets/sunglasses.webp',
      'wearing classic sunglasses'
    )
  ]
};
