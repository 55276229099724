<div class="image-generation">
  <div class="image-generation_left">
    <div class="image-generation_header">
      <p>Generate images</p>
      <stxt-button
        [buttonConfig]="galleryButtonConfig"
        (click)="toggleRightPanel()"
        (keydown.enter)="toggleRightPanel()"
        class="gallery-button"
      >
        <stxt-svg-icon [iconConfig]="imageGalleryIcon" class="gallery-button_svg"></stxt-svg-icon>
        <p>My images</p>
      </stxt-button>
    </div>
    <div class="image-generation_left__content">
      <h4 class="left-content_header">Who would you like to create with?</h4>
      <div class="left-content_carousel">
        <stxt-creators-carousel
          [creators]="creators"
          [selectedCreator]="selectedCreator"
          (selectCreatorEvent)="updateSelectedCreator($event)"
        ></stxt-creators-carousel>
      </div>
      <h4 class="left-content_subheader">What would you like to create?</h4>
      <div
        class="left-content_input relative"
        stxtClickOutside
        (clickOutside)="isShowSuggestions = false"
      >
        <div
          [@fade]
          *ngIf="filteredPrompts?.length && isShowSuggestions"
          class="image-generation_prompts absolute w-full"
        >
          <div *ngFor="let suggestedItem of filteredPrompts?.slice(0, 2)">
            <stxt-suggested-prompt
              [suggestedPrompt]="suggestedItem"
              [usageCase]="EPromptUsage.ImageGeneration"
              [userInput]="userInput"
              (click)="selectPrompt(suggestedItem)"
              (keydown.enter)="selectPrompt(suggestedItem)"
            ></stxt-suggested-prompt>
          </div>
        </div>
        <textarea
          #textarea
          [(ngModel)]="userInput"
          class="image-generation_textarea"
          placeholder="Type what you’d like to see or select ideas to help you describe what you’d like"
          (input)="onInputChange(textarea.value)"
          (focus)="isShowSuggestions = true"
        ></textarea>
      </div>

      <div class="left-content_suggestions">
        <stxt-image-presets (selectedPresetPrompt)="onPresetPrompt($event)"></stxt-image-presets>
      </div>
    </div>

    <div class="image-generation_left__footer">
      <div class="footer-text">
        <p>Create with {{ selectedCreator?.name }}</p>
        <div class="footer-text_token">
          <stxt-svg-icon class="token-icon" [iconConfig]="tokenIcon"></stxt-svg-icon>
          <p
            class="cursor-default"
            ngbTooltip="Ten tokens"
            placement="top"
            tooltipClass="custom-tooltip"
          >
            {{ generatePrice }}
          </p>
          <span>• 1 image</span>
        </div>
      </div>
      <stxt-button
        [buttonConfig]="generateButton"
        class="generate-button"
        (click)="generateImage()"
        (keydown.enter)="generateImage()"
        [disabled]="!userInput.length"
      >
        {{ generateButton.text }}
        <stxt-svg-icon [iconConfig]="arrowRightIcon"></stxt-svg-icon>
      </stxt-button>
    </div>
  </div>
  <div
    [ngClass]="{ 'image-generation_right__visible': isRightPanelVisible }"
    class="image-generation_right"
  >
    <div class="image-generation_header">
      <p>My images</p>
      <div class="flex gap-3 relative">
        <button
          *ngIf="availableCreatorsFilters.length"
          class="button-wrapper btn-nostyle"
          [ngClass]="{ 'filter-active': isShowFilter }"
        >
          <stxt-svg-icon
            [iconConfig]="filterIcon"
            (click)="showFilters($event)"
            (keydown.enter)="showFilters($event)"
          ></stxt-svg-icon>
        </button>
        <button class="button-wrapper btn-nostyle close-icon_wrapper" (click)="toggleRightPanel()">
          <stxt-svg-icon class="close-icon" [iconConfig]="closeIcon"></stxt-svg-icon>
        </button>
        <div
          *ngIf="isShowFilter"
          class="image-generation_filter absolute"
          stxtClickOutside
          (clickOutside)="isShowFilter = false"
        >
          <ng-container *ngFor="let creator of availableCreatorsFilters">
            <div
              (click)="filterImagesByCreator(creator)"
              (keydown.enter)="filterImagesByCreator(creator)"
              tabindex="-1"
              class="image-generation_filter__creator flex items-center"
            >
              <input
                class="form-check-input"
                [id]="creator?.name"
                type="checkbox"
                [checked]="isChecked(creator.id)"
              />
              <p>{{ creator?.name }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="image-generation_right__content">
      <div class="generated-images">
        <ng-container *ngIf="isGeneratingImage">
          <stxt-thumbnail-loader [@fade] class="generating_loader"></stxt-thumbnail-loader>
        </ng-container>
        <ng-container *ngFor="let image of imagesToDisplay; let i = index">
          <div class="generated-images_item__wrapper">
            <img
              [ngSrc]="image.url"
              alt="Img"
              class="generated-images_item"
              [ngStyle]="{ 'animation-delay': i * 0.1 + 's' }"
              (load)="onImageLoad($event)"
              (click)="openMediaFullScreen(image)"
              (keydown.enter)="openMediaFullScreen(image)"
              tabindex="-1"
              fill
              priority
            />
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="isToastMessage">
        <stxt-toast
          [toast]="toast"
          (closeToast)="isToastMessage = false"
          class="fixed-toast"
        ></stxt-toast>
      </ng-container>
      <ng-container *ngIf="!imagesToDisplay.length && !isGeneratingImage">
        <div class="no-images_wrapper">
          <stxt-empty-state
            [emptyStateData]="emptyStateData"
            (emptyStateButtonClicked)="isRightPanelVisible = false"
            class="no-images_empty-state"
          ></stxt-empty-state>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="mediaFullScreen">
  <stxt-media-lightbox
    [generatedMediaList]="imagesToDisplay"
    [selectedGeneratedMedia]="selectedMedia"
    [usageCase]="EMediaLightboxUsage.ImageGeneration"
    (closeFullScreen)="mediaFullScreen = false"
    [postDescription]="postDescription"
    (regenerateImage)="openRegenerateModal($event)"
  ></stxt-media-lightbox>
</ng-container>
