import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
  IGenerateImage,
  IGenerateImageRequest,
  IGenerateResponse
} from '../interfaces/media.interfaces';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private readonly apiUrl: string = `${environment.apiBaseUrl}`;

  constructor(private readonly http: HttpClient) {}

  generateImage(requestBody: IGenerateImageRequest): Observable<IGenerateResponse> {
    const endpoint = this.apiUrl + `/medias/generate-image`;
    return this.http.post<IGenerateResponse>(endpoint, requestBody);
  }

  getMedias(): Observable<IGenerateImage[]> {
    const endpoint = this.apiUrl + `/medias/vault`;
    return this.http.get<IGenerateImage[]>(endpoint);
  }
}
