import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { filter, Subscription, take } from 'rxjs';

import { TokensService } from 'app/shared/services/tokens.service';
import { AuthService } from 'app/auth/auth.service';

import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ToastComponent } from '../toast/toast.component';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IToastConfig } from '../../interfaces/toast-config.interfaces';
import { IPaymentResult } from '../../../interfaces/payments.interfaces';

import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { ETokenType, ETokenUsageCase } from 'app/shared/enums/tokens.enums';
import { EToastType } from '../../enums/toast.enums';
import { EPaymentStatus } from '../../../enums/payments.enums';

@Component({
  selector: 'stxt-token-status',
  templateUrl: './token-status.component.html',
  styleUrls: ['./token-status.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgIconComponent, ToastComponent]
})
export class TokenStatusComponent implements OnInit, OnDestroy {
  @Input() type?: ETokenType;
  @Input() tokenPlans?: number;
  @Input() usageCase?: ETokenUsageCase;
  @Output() tokenType: EventEmitter<ETokenType> = new EventEmitter<ETokenType>();
  tokens: number;
  isToastMessage: boolean = false;
  ETokenUsageCase = ETokenUsageCase;
  ETokenType = ETokenType;
  previousStatus: ETokenType;
  arrow_down_icon: ISvgConfig = { name: 'arrow_down', fill: ESvgTypes.None };
  toast: IToastConfig = {
    toastType: EToastType.FAIL
  };
  subscriptions = new Subscription();

  constructor(
    private readonly tokensService: TokensService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initializeTokens();
  }

  private initializeTokens(): void {
    this.subscriptions.add(
      this.tokensService.tokensBalance$.subscribe(balance => {
        this.tokens = balance;
      })
    );

    this.subscriptions.add(
      this.authService.$isUser.pipe(filter(Boolean), take(1)).subscribe(() => {
        this.tokensService.fetchTokensBalance();
      })
    );
  }

  toggleToast(paymentResult: IPaymentResult): void {
    switch (paymentResult.status) {
      case EPaymentStatus.Success:
        this.toast = {
          toastHeading: 'Wallet reloaded',
          toastSubheading: `You have ${this.tokens} tokens`,
          toastType: EToastType.SUCCESS
        };
        break;
      case EPaymentStatus.Error:
        this.toast = {
          toastHeading: 'Wallet could not be reloaded',
          toastType: EToastType.FAIL
        };
        break;
    }
    this.isToastMessage = true;
  }

  get statusType(): ETokenType {
    let status: ETokenType;

    if (this.usageCase === ETokenUsageCase.Card && this.tokens === 0) {
      status = ETokenType.Empty;
    } else if (this.tokens === 0 || this.type === ETokenType.Add) {
      status = ETokenType.Add;
      this.type = ETokenType.Add;
    } else if (this.tokens < 10) {
      status = ETokenType.LowBalance;
    } else {
      status = ETokenType.HasFunds;
    }

    if (this.previousStatus !== status) {
      this.previousStatus = status;
      this.tokenType.emit(status);
    }

    return status;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
