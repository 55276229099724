/* eslint-disable */
import { Injectable } from '@angular/core';
import { ELocalStorageKey } from '../enums/shared.enums';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  dataLayer = window.dataLayer;

  trackPageLoaded(
    pageName: string,
    path: string,
    referrer: string,
    title: string,
    url: string,
    utmCampaign: string,
    utmContent: string,
    utmMedium: string,
    utmSource: string
  ) {
    this.dataLayer.push({
      page_name: pageName,
      path: path,
      referrer: referrer,
      title: title,
      url: url,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium,
      utm_source: utmSource,
      time_segment: new Date().toISOString()
    });
  }

  login(name: string, email: string, userId: string): void {
    this.dataLayer.push({
      event: 'login',
      eventModel: {
        name: name,
        email: email,
        userId: userId
      }
    });
  }

  click(clickText: string, clickUrl: string, pageName: string, type: string, url: string): void {
    this.dataLayer.push({
      event: 'click',
      click_text: clickText,
      click_url: clickUrl,
      page_name: pageName,
      type: type,
      url: url,
      time_segment: new Date().toISOString()
    });
  }

  chatInitiated(
    userId: string,
    creatorName: string,
    chatState: string,
    chatId: string,
    creatorId: string
  ) {
    this.dataLayer.push({
      event: 'chat_initiated',
      user_id: userId,
      creator_name: creatorName,
      chat_state: chatState,
      chat_id: chatId,
      creator_id: creatorId,
      time_segment: new Date().toISOString()
    });
  }

  onboardingProfile(stepName: string, userId: string, creatorName: string, creatorId: string) {
    if (localStorage.getItem(ELocalStorageKey.ProfileCompleted) === 'true') return;
    this.dataLayer.push({
      event: 'onboarding_step_completed',
      step_name: stepName,
      user_id: userId,
      creator_name: creatorName,
      creator_id: creatorId,
      time_segment: new Date().toISOString()
    });
  }

  voiceNotePlayed(userId: string, creatorName: string, chatState: string, creatorId: string) {
    this.dataLayer.push({
      event: 'voice_note_played',
      user_id: userId,
      creator_name: creatorName,
      chat_state: chatState,
      creator_id: creatorId,
      time_segment: new Date().toISOString()
    });
  }

  subscribeMessageSent(userId: string, stepName: string, creatorId: string) {
    this.dataLayer.push({
      event: 'subscribe_message_sent',
      user_id: userId,
      step_name: stepName,
      creator_id: creatorId,
      time_segment: new Date().toISOString()
    });
  }
}
