<ng-container *ngIf="!isEditingTokens; else editingTokens">
  <div class="tokens_wrapper">
    <div [hidden]="showCheckoutComponent" class="tokens-card">
      <div class="tokens-card_balance">
        <stxt-token-status
          #tokensStatusComponent
          [usageCase]="ETokenUsageCase.Card"
          (tokenType)="getTokenType($event)"
        ></stxt-token-status>
        <div *ngIf="tokenType === ETokenType.Empty" class="empty-balance-badge">
          <stxt-svg-icon
            class="empty-balance-badge_svg"
            [iconConfig]="lowBalanceSvg"
          ></stxt-svg-icon>
          <span class="empty-balance-badge_text">Add funds</span>
        </div>
        <div *ngIf="tokenType === ETokenType.LowBalance" class="low-balance-badge">
          <stxt-svg-icon class="low-balance-badge_svg" [iconConfig]="lowBalanceSvg"></stxt-svg-icon>
          <span class="low-balance-badge_text">Low funds</span>
        </div>
      </div>
      <div class="tokens-card_tabs">
        <button
          *ngFor="let plan of plans"
          class="tokens-card_tabs__tab"
          [ngClass]="{ 'tokens-card_tabs__tab_active': selectedPlan === plan.name }"
          (click)="selectPlan(plan.name)"
        >
          {{ plan.name }}
        </button>
      </div>

      <div class="tokens-card_content" *ngIf="activePlan">
        <div class="tokens-card_content__info">
          <div class="tokens-card_content__amount">
            <div class="dot"></div>
            {{ activePlan.tokens_quantity }}
          </div>
          <div class="tokens-card_content__discount" *ngIf="activePlan.save !== '0%'">
            <p *ngIf="activePlan?.save">SAVE {{ activePlan.save }}</p>
            <span>
              {{ activePlan.price / 100 | currency }} • ${{ activePlan.pricePerToken }}/token
            </span>
          </div>
        </div>
        <stxt-button
          class="tokens-card_content__button"
          [buttonConfig]="buyNowButton"
          (click)="showCheckout()"
          (keydown.enter)="showCheckout()"
        >
          {{ buyNowButton.text }}
        </stxt-button>
      </div>
    </div>
  </div>
  <stxt-checkout
    *ngIf="showCheckoutComponent"
    [billingData]="billingData"
    [firstCheckOut]="true"
    [tokenData]="activePlan"
    [paymentType]="EPaymentType.Token"
    (isEditingTokens)="isEditingTokens = $event"
    (closeCheckoutEvent)="updateTokensScreen($event)"
  ></stxt-checkout>
</ng-container>

<ng-template #editingTokens>
  <div class="tokens-edit">
    <div class="tokens-edit_header">
      <p>Get tokens</p>
      <span>How many tokens would you like to add to your wallet:</span>
    </div>
    <div class="tokens-edit_content">
      <div class="tokens-edit_grid">
        <div
          *ngFor="let plan of plans"
          class="tokens-edit_plan"
          [ngClass]="{ 'tokens-edit_plan__selected': selectedPlan === plan.name }"
          (click)="selectedPlan = plan.name"
          (keydown.enter)="selectedPlan = plan.name"
          tabindex="-1"
        >
          <stxt-tokens-plan-card
            [plan]="plan"
            [selectedPlan]="selectedPlan"
            class="w-full"
          ></stxt-tokens-plan-card>
        </div>
      </div>
    </div>
    <div class="tokens-edit_footer">
      <p class="tokens-edit_footer__heading">What tokens get you</p>
      <div class="tokens-edit_footer__list" *ngFor="let feature of tokenFeatures">
        <stxt-svg-icon [iconConfig]="checkSvg" class="check-svg"></stxt-svg-icon>
        <p>
          <span class="tokens-edit_footer__list_item">{{ feature.mainText }}</span>
          <span class="tokens-edit_footer__list_item">{{ feature.subText }}</span>
        </p>
      </div>
    </div>
    <stxt-button
      [buttonConfig]="confirmBtn"
      (click)="confirmEditingTokens(selectedPlan)"
      (keydown.enter)="confirmEditingTokens(selectedPlan)"
      class="tokens-edit_footer__button"
    >
      {{ confirmBtn.text }}
    </stxt-button>
  </div>
</ng-template>
