import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { INPUT_VALUE_ACCESSOR, INPUT_VALIDATION } from '../input/input.component';

@Component({
  selector: 'stxt-toggle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
  providers: [INPUT_VALUE_ACCESSOR, INPUT_VALIDATION]
})
export class ToggleComponent {
  @Input() isChecked: boolean = false;
  @Output() customChange = new EventEmitter<boolean>();

  private onChange: (value: boolean) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: boolean): void {
    this.isChecked = value;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onToggle() {
    this.isChecked = !this.isChecked;
    this.onChange(this.isChecked);
    this.customChange.emit(this.isChecked);
    this.onTouched();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == null) {
      return { required: true };
    }
    return null;
  }
}
