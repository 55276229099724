<div class="tokens-plan">
  <div class="flex justify-content-between w-full">
    <div class="tokens-plan_type">
      <span class="tokens-plan_icon">{{ getPlanIcon(plan.name) }}</span>
      <span>{{ plan.name }}</span>
    </div>
    <div class="tokens-plan_radio">
      <div class="radio-outer" [ngClass]="{ 'radio-outer__selected': selectedPlan === plan.name }">
        <div
          class="radio-inner"
          [ngClass]="{ 'radio-inner__selected': selectedPlan === plan.name }"
        ></div>
      </div>
    </div>
  </div>
  <div class="tokens-plan_amount">
    <div class="tokens-edit">
      <div class="dot"></div>
      <p>{{ plan.tokens_quantity }}</p>

      <div class="tokens-plan_save" *ngIf="plan.save !== '0%' && plan.save?.length">
        SAVE {{ plan.save }}
      </div>
    </div>
    <div class="tokens-plan_price">
      <span class="per-token">${{ plan.pricePerToken }}/token</span>
    </div>
  </div>
</div>
