import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';

import { first } from 'rxjs';

import { TokensService } from '../../../shared/services/tokens.service';

import { ButtonComponent } from 'app/shared/components/button/button.component';
import { CheckoutComponent } from 'app/components/payment/checkout/checkout.component';
import { SvgIconComponent } from '../../../shared/components/svg-icon/svg-icon.component';
import { TokenStatusComponent } from 'app/shared/components/token-status/token-status.component';
import { TokensPlanCardComponent } from '../tokens-plan-card/tokens-plan-card.component';

import {
  ITokenFeature,
  ITokenPlan,
  ITokensPackageResponse
} from 'app/shared/interfaces/token.interfaces';
import { IBillingInfo } from 'app/interfaces/profile.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { IPaymentResult } from '../../../interfaces/payments.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';
import { IToastConfig } from 'app/shared/interfaces/toast-config.interfaces';

import { EButtonTypes, EButtonSizes } from 'app/shared/enums/button.enums';
import { EPaymentType } from 'app/enums/payments.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { ETokenPlan, ETokenType, ETokenUsageCase } from 'app/shared/enums/tokens.enums';

@Component({
  selector: 'stxt-tokens-card',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CheckoutComponent,
    SvgIconComponent,
    TokenStatusComponent,
    TokensPlanCardComponent
  ],
  templateUrl: './tokens-card.component.html',
  styleUrls: ['./tokens-card.component.scss']
})
export class TokensCardComponent implements OnInit {
  @Input() billingData: IBillingInfo[] = [];
  @Input() isEditingTokens: boolean = false;
  @Input() showCheckoutComponent: boolean = false;
  @Output() showCheckoutEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('tokensStatusComponent') tokensStatusComponent: TokenStatusComponent;
  checkSvg: ISvgConfig = { name: 'done', fill: ESvgTypes.None };
  lowBalanceSvg: ISvgConfig = { name: 'info', fill: ESvgTypes.None };
  confirmBtn: IButtonConfig = {
    text: 'Next',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  buyNowButton: IButtonConfig = {
    text: 'Buy now',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Small
  };
  toast: IToastConfig = {
    toastSubheading: 'Tokens have been added to your account'
  };
  tokenFeatures: ITokenFeature[] = [
    {
      mainText: 'Custom images',
      subText: 'NSFW and X-rated'
    },
    {
      mainText: 'Fine-tune creations',
      subText: 'regenerate whenever you like'
    },
    {
      mainText: 'VIP content access',
      subText: 'adult videos, photos, and more'
    }
  ];
  selectedPlan: string = 'Popular';
  ETokenUsageCase = ETokenUsageCase;
  EPaymentType = EPaymentType;
  plans: ITokenPlan[] = [];
  activePlan: ITokenPlan;
  tokenType: ETokenType;
  ETokenType = ETokenType;

  constructor(private readonly tokensService: TokensService) {}

  ngOnInit(): void {
    this.activePlan = this.plans[0];
    this.getTokensPackage();
    this.showCheckoutEvent.emit(this.showCheckoutComponent);
  }

  getTokensPackage(): void {
    this.tokensService
      .getTokensPackage()
      .pipe(first())
      .subscribe({
        next: (res: ITokensPackageResponse) => {
          if (!res.data) return;
          this.plans = res.data;
          this.plans.forEach(plan => this.updateTokensPlan(plan));
          this.plans.sort((a, b) => {
            if (a.name === ETokenPlan.Popular) return -1;
            if (b.name === ETokenPlan.Popular) return 1;
            return 0;
          });
          this.activePlan = this.plans.find(plan => plan.name === ETokenPlan.Popular);
        },
        error: error => console.error('Unexpected error:', error)
      });
  }

  updateTokensPlan(plan: ITokenPlan): ITokenPlan {
    switch (plan.tokens_quantity) {
      case 10:
        plan.name = ETokenPlan.Basic;
        break;
      case 50:
        plan.name = ETokenPlan.Starter;
        plan.save = '1%';
        break;
      case 100:
        plan.name = ETokenPlan.Popular;
        plan.save = '1%';
        break;
      case 500:
        plan.name = ETokenPlan.Premium;
        plan.save = '1%';
        break;
    }
    plan.pricePerToken = plan.name === ETokenPlan.Basic ? '0.10' : '0.099';
    return plan;
  }

  updateTokensScreen(paymentResult: IPaymentResult): void {
    this.tokensStatusComponent.toggleToast(paymentResult);
    this.tokensStatusComponent.tokenType.emit();
    this.showCheckoutComponent = false;
    this.showCheckoutEvent.emit(this.showCheckoutComponent);
    if (!this.isEditingTokens) return;
    /// Timeout used to handle event after toast message is shown;
    setTimeout(() => this.tokensService.getMoreTokensEvent$.next(false), 5000);
  }

  getTokenType(tokenType: ETokenType): void {
    this.tokenType = tokenType;
  }

  showCheckout(): void {
    this.showCheckoutComponent = !this.showCheckoutComponent;
    this.showCheckoutEvent.emit(this.showCheckoutComponent);
  }

  confirmEditingTokens(selectedPlan: string): void {
    this.isEditingTokens = false;
    this.activePlan = this.plans.find(plan => plan.name === selectedPlan) || this.plans[0];
  }

  selectPlan(planName: string): void {
    this.selectedPlan = planName;
    this.activePlan = this.plans.find(plan => plan.name === planName) || this.plans[0];
  }
}
