import { Component } from '@angular/core';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { ToggleComponent } from 'app/shared/components/toggle/toggle.component';

import { ESvgTypes } from 'app/shared/enums/svg.enums';

import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

@Component({
  selector: 'stxt-notifications',
  standalone: true,
  imports: [ToggleComponent, SvgIconComponent],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {
  
  public arrowSvgConfig: ISvgConfig = { name: 'arrow_right', fill: ESvgTypes.None };

}
