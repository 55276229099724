<div class="media_overlay flex">
  <div class="media_lightbox__head flex">
    <div class="flex">
      <stxt-svg-icon
        class="checkout_icon"
        [iconConfig]="closeSvg"
        (click)="closeFullScreen.emit()"
        (keydown.enter)="closeFullScreen.emit()"
        tabindex="-1"
      ></stxt-svg-icon>
      <div *ngIf="usageCase === EMediaLightboxUsage.MasonryGallery" class="media_lightbox__slides">
        {{ creator.name }} {{ postDescription }}
      </div>
      <div *ngIf="usageCase === EMediaLightboxUsage.ImageGeneration" class="media_lightbox__slides">
        {{ postDescription }}
      </div>
    </div>
  </div>
  <div class="media_lightbox__carousel h-full">
    <ngb-carousel #carousel [showNavigationArrows]="false" [showNavigationIndicators]="false">
      <ng-container *ngFor="let media of imagesToDisplay; index as i">
        <ng-template
          id="ngb-slide-{{
            usageCase === EMediaLightboxUsage.MasonryGallery ? media.media_id : media.id
          }}"
          ngbSlide
        >
          <div
            [style.background-image]="
              'url(' +
              (usageCase === EMediaLightboxUsage.MasonryGallery &&
              media?.type !== ECreatorMediaTypes.Video
                ? media.value
                : media?.type === ECreatorMediaTypes.Video
                  ? creator.image
                  : media.url) +
              ')'
            "
            class="picsum-img-wrapper media_lightbox__body relative h-full"
          >
            <div class="media_lightbox__image-wrapper h-full relative">
              <img
                *ngIf="media?.type !== ECreatorMediaTypes.Video"
                [src]="usageCase === EMediaLightboxUsage.MasonryGallery ? media.value : media.url"
                class="carousel_image"
                alt="{{ i }}"
              />
              <stxt-media-video
                *ngIf="media?.type === ECreatorMediaTypes.Video"
                [media]="media?.value"
                [videoType]="ECreatorVideoTypes.Fullscreen"
                [mediaId]="'full' + media?.media_id"
                class="carousel_video"
              ></stxt-media-video>
            </div>
            <div
              *ngIf="imagesToDisplay?.length > 1"
              class="media_lightbox__navigation left-nav absolute"
            >
              <div
                [ngClass]="{ 'hidden-nav': i === 0 }"
                (click)="carouselNavigation(ECarouselNavigation.Previous, media)"
                (keydown.arrowLeft)="carouselNavigation(ECarouselNavigation.Previous, media)"
                tabindex="0"
              >
                <stxt-svg-icon [iconConfig]="leftNavigation"></stxt-svg-icon>
              </div>
            </div>
            <div
              *ngIf="imagesToDisplay?.length > 1"
              class="media_lightbox__navigation right-nav absolute"
            >
              <div
                [ngClass]="{ 'hidden-nav': i === imagesToDisplay.length - 1 }"
                (click)="carouselNavigation(ECarouselNavigation.Next, media)"
                (keydown.arrowRight)="carouselNavigation(ECarouselNavigation.Next, media)"
                tabindex="0"
              >
                <stxt-svg-icon [iconConfig]="rightNavigation"></stxt-svg-icon>
              </div>
            </div>

            <button
              class="regenerate-button btn-nostyle"
              *ngIf="usageCase === EMediaLightboxUsage.ImageGeneration"
              (click)="onRegenerateImage()"
            >
              <stxt-svg-icon class="token-badge" [iconConfig]="tokenIcon"></stxt-svg-icon>
              <p>2 to regenerate</p>
            </button>
          </div>
        </ng-template>
      </ng-container>
    </ngb-carousel>
  </div>
</div>
