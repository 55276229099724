import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnalyticsService } from '../../shared/services/analytics.service';

import { MessageMediaComponent } from './message-media/message-media/message-media.component';
import { PlayVoiceButtonComponent } from '../../shared/components/play-voice-button/play-voice-button.component';

import { IMessage } from 'app/interfaces/conversations.interfaces';
import { IAudioState } from '../../interfaces/creator.interfaces';

import { EPlayButtonUsage } from '../../shared/enums/shared.enums';
import { EMessageItemCategory, EChatStates } from '../../enums/conversation.enums';

@Component({
  selector: 'stxt-message',
  standalone: true,
  imports: [CommonModule, MessageMediaComponent, PlayVoiceButtonComponent],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent implements OnChanges {
  @Input() messageItem: IMessage;
  @Input() userId: string;
  @Input() creatorName: string;
  @Input() creatorId: string;
  @Input() voiceSupported: boolean;
  @Input() selectedMessageAudio: number;
  @Output() triggerPayment: EventEmitter<void> = new EventEmitter<void>();
  @Output() media: EventEmitter<void> = new EventEmitter<void>();
  @Output() isPreviewMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() updatePlayMessageState: EventEmitter<IAudioState> = new EventEmitter<IAudioState>();
  @ViewChild('playMessage') playMessage!: PlayVoiceButtonComponent;
  mediaLoaded: boolean = false;
  protected readonly EPlayButtonUsage = EPlayButtonUsage;
  protected readonly EMessageItemCategory = EMessageItemCategory;

  constructor(private readonly analyticsService: AnalyticsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['selectedMessageAudio'] &&
      this.selectedMessageAudio !== this.messageItem.timestamp &&
      this.selectedMessageAudio !== undefined
    ) {
      this.playMessage?.stopAudio();
    }
  }

  trackMessagePlaying(isPlaying: boolean): void {
    this.updatePlayMessageState.emit({ id: this.messageItem.timestamp, isPlaying: isPlaying });
    if (!isPlaying) return;
    this.analyticsService.voiceNotePlayed(
      this.userId,
      this.creatorName,
      EChatStates.FREEFLOW,
      this.creatorId
    );
  }
}
