import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { catchError, EMPTY, filter, finalize, first, Observable, of, switchMap } from 'rxjs';

import { ProfileService } from 'app/shared/services/profile.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';

import { ProfileOptionComponent } from '../profile-option/profile-option.component';
import { InputComponent } from 'app/shared/components/input/input.component';
import { ButtonComponent } from 'app/shared/components/button/button.component';
import { ToastComponent } from 'app/shared/components/toast/toast.component';
import { SvgIconComponent } from 'app/shared/components/svg-icon/svg-icon.component';

import { IProfileOption, IUserPreferences } from 'app/interfaces/profile.interfaces';
import { IInputCustomConfig } from 'app/shared/interfaces/input.interfaces';
import { IButtonConfig } from 'app/shared/interfaces/button.interfaces';
import { IToastConfig } from 'app/shared/interfaces/toast-config.interfaces';
import { ISvgConfig } from 'app/shared/interfaces/svg.interfaces';

import { EProfileState } from 'app/enums/profile.enums';
import { EInputSupportMessage, EInputCustomType, EInputUsage } from 'app/shared/enums/input.enums';
import { EButtonTypes, EButtonSizes } from 'app/shared/enums/button.enums';
import { ESvgTypes } from 'app/shared/enums/svg.enums';
import { EToastType } from '../../../shared/enums/toast.enums';

@Component({
  selector: 'stxt-preferences',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfileOptionComponent,
    InputComponent,
    ButtonComponent,
    ToastComponent,
    SvgIconComponent
  ],
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss'
})
export class PreferencesComponent implements OnInit, OnChanges {
  @Input() interestsData: string[];
  @Input() genderData: string;
  @Input() name: string;

  identifyOptions: IProfileOption[] = [
    { label: 'Male', value: 'male', selected: false },
    { label: 'Female', value: 'female', selected: false },
    { label: 'Transgender', value: 'transgender', selected: false }
  ];
  interestsOptions: IProfileOption[] = [
    { label: 'Women', value: 'women', selected: false },
    { label: 'Men', value: 'men', selected: false },
    { label: 'Trans women', value: 'trans_women', selected: false },
    { label: 'Trans men', value: 'trans_men', selected: false }
  ];
  nameInput: IInputCustomConfig = {
    placeholder: 'Introduce yourself',
    supportingText: {
      text: EInputSupportMessage.FieldRequired
    },
    type: EInputCustomType.Text,
    usageCase: EInputUsage.Page
  };
  selectedInterests: string[] = [];
  selectedGender: string;
  userPreferences: IUserPreferences;
  userId = localStorage.getItem('userId');
  preferencesForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(30)])
  });
  buttonConfig: IButtonConfig = {
    text: 'Save changes',
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  editButtonConfig: IButtonConfig = {
    fill: EButtonTypes.SecondaryFilled,
    buttonSize: EButtonSizes.Default
  };
  editIcon: ISvgConfig = {
    name: 'pen',
    fill: ESvgTypes.None
  };
  toast: IToastConfig = {
    toastSubheading: 'Preferences have been updated',
    toastType: EToastType.SUCCESS
  };
  public isToastMessage: boolean = false;
  public isEditing: boolean = false;

  constructor(
    private readonly profileService: ProfileService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.preferencesForm.get('name').statusChanges.subscribe(() => {
      this.updateNameInputSupportMessage();
    });
  }

  ngOnChanges(): void {
    this.selectedGender = this.genderData ?? '';
    this.selectedInterests = this.interestsData ?? [];
    this.setSelectedOption(this.genderData);
    this.setSelectedInterests(this.interestsData);
    this.preferencesForm.patchValue({ name: this.name });
    this.updateEditingState();
  }

  private updateEditingState(): void {
    const hasAllData = Boolean(this.genderData && this.interestsData?.length && this.name);

    this.isEditing = !hasAllData;
  }

  selectGender(selectedOption: IProfileOption): void {
    if (selectedOption.selected || this.buttonConfig.isLoading) return;
    selectedOption.selected = true;
    this.identifyOptions.forEach(option => {
      if (option !== selectedOption) {
        option.selected = false;
      }
    });
    this.selectedGender = selectedOption.value;
    this.genderData = selectedOption.value;
    this.analyticsService.onboardingProfile(
      EProfileState.PROFILE_IDENTIFY,
      this.userId,
      null,
      null
    );
  }

  private updateNameInputSupportMessage(): void {
    const nameControl = this.preferencesForm.get('name');

    if (nameControl.hasError('required')) {
      this.nameInput.supportingText.text = EInputSupportMessage.FieldRequired;
    } else if (nameControl.hasError('maxlength')) {
      this.nameInput.supportingText.text = EInputSupportMessage.NameMaxLength;
    } else if (nameControl.hasError('apiError')) {
      this.nameInput.supportingText.text = EInputSupportMessage.ApiError;
    } else {
      this.nameInput.supportingText.text = '';
    }
  }

  toggleInterest(selectedOption: IProfileOption): void {
    if (this.buttonConfig.isLoading) return;
    selectedOption.selected = !selectedOption.selected;
    if (selectedOption.selected) {
      this.selectedInterests.push(selectedOption.value);
      this.interestsData = this.selectedInterests;
    } else {
      this.selectedInterests = this.selectedInterests.filter(
        interest => interest !== selectedOption.value
      );
      this.interestsData = this.selectedInterests;
    }
  }

  setSelectedOption(gender: string): void {
    this.identifyOptions.forEach(option => {
      option.selected = option.value === gender?.toLowerCase();
    });
  }

  setSelectedInterests(interests: string[]): void {
    this.interestsOptions.forEach(option => {
      option.selected = interests?.includes(option.value?.toLowerCase());
    });
  }

  saveProfilePreferences(): void {
    this.prepareUserPreferences()
      .pipe(
        first(),
        filter((userPreferences): userPreferences is IUserPreferences => !!userPreferences),
        switchMap(userPreferences =>
          this.profileService.updateProfileData<IUserPreferences>(userPreferences)
        ),
        finalize(() => {
          this.buttonConfig.isLoading = false;
          this.preferencesForm.enable();
        }),
        catchError(error => {
          console.error('Error updating preferences:', error);
          this.nameInput.supportingText = {
            text: 'Failed to update preferences. Please try again.'
          };
          this.preferencesForm.controls['name'].setErrors({ apiError: true });
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.isToastMessage = true;
        this.name = this.preferencesForm.value.name;
        this.updateEditingState();
      });
  }

  prepareUserPreferences(): Observable<IUserPreferences> {
    const isNameValid = this.preferencesForm.valid;
    if (!isNameValid) {
      this.preferencesForm.controls['name'].setErrors({ required: true });
      this.preferencesForm.controls['name'].markAsTouched();
      this.preferencesForm.updateValueAndValidity();
      return of(null);
    }
    this.buttonConfig.isLoading = true;
    this.preferencesForm.disable();
    this.userPreferences = {
      gender: this.selectedGender?.toLowerCase(),
      interests: this.selectedInterests,
      name: this.preferencesForm.value.name.trim()
    };
    this.checkAnalytics(this.userPreferences);
    return of(this.userPreferences);
  }

  public getInterestLabels(): string {
    return this.selectedInterests
      .map(value => this.interestsOptions.find(option => option.value === value)?.label)
      .filter(Boolean)
      .join(', ');
  }

  checkAnalytics(userPreferences: IUserPreferences): void {
    const analyticsMap = {
      name: { condition: Boolean(userPreferences.name), state: EProfileState.PROFILE_ASK_NAME },
      interests: {
        condition: userPreferences.interests.length > 0,
        state: EProfileState.PROFILE_INTERESTS
      },
      gender: { condition: Boolean(userPreferences.gender), state: EProfileState.PROFILE_IDENTIFY }
    };

    Object.values(analyticsMap)
      .filter(({ condition }) => condition)
      .forEach(({ state }) => {
        this.analyticsService.onboardingProfile(state, this.userId, null, null);
      });
  }
}
