export enum ETokenType {
  HasFunds = 'hasFunds',
  LowBalance = 'lowBalance',
  Add = 'add',
  Empty = 'empty'
}

export enum ETokenUsageCase {
  Card = 'card',
  Header = 'header'
}

export enum ETokenPlan {
  Basic = 'Basic',
  Premium = 'Premium',
  Popular = 'Popular',
  Starter = 'Starter'
}
