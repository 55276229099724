<div
  class="token_status"
  [ngClass]="{
    token_status_hasFunds: statusType === ETokenType.HasFunds,
    token_status_lowBalance: statusType === ETokenType.LowBalance,
    token_status_add: statusType === ETokenType.Add,
    token_status_empty: statusType === ETokenType.Empty
  }"
>
  <div class="token_status__dot"></div>
  <ng-container *ngIf="type === 'add'"><p class="token_status__text">Add tokens</p></ng-container>
  <ng-container *ngIf="type !== 'add' && tokens !== undefined">
    <span class="token_status__number">
      <ng-container *ngIf="tokens">
        <span
          *ngIf="usageCase === ETokenUsageCase.Header && tokens < 10"
          [ngClass]="{ token_status__number_faded: tokens }"
        >
          00
        </span>
        <span
          *ngIf="usageCase === ETokenUsageCase.Card && tokens < 10 && tokens > 0"
          [ngClass]="{ token_status__number_faded: tokens }"
        >
          00
        </span>
        <span>{{ tokens }}</span>
      </ng-container>
      <span *ngIf="tokens === 0 && usageCase === ETokenUsageCase.Card">0</span>
    </span>
    <ng-container *ngIf="statusType === ETokenType.LowBalance">
      <span *ngIf="usageCase === ETokenUsageCase.Header" class="token_status__plus">+</span>
    </ng-container>
    <ng-container
      *ngIf="statusType === ETokenType.HasFunds && usageCase === ETokenUsageCase.Header"
    >
      <stxt-svg-icon [iconConfig]="arrow_down_icon"></stxt-svg-icon>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="isToastMessage">
  <stxt-toast
    [toast]="toast"
    (closeToast)="isToastMessage = false"
    class="fixed-toast w-full"
  ></stxt-toast>
</ng-container>
