import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ITokenPlan } from '../../../shared/interfaces/token.interfaces';

import { ETokenPlan } from '../../../shared/enums/tokens.enums';

@Component({
  selector: 'stxt-tokens-plan-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tokens-plan-card.component.html',
  styleUrl: './tokens-plan-card.component.scss'
})
export class TokensPlanCardComponent {
  @Input() plan: ITokenPlan;
  @Input() selectedPlan: string;

  getPlanIcon(planName: ETokenPlan): string {
    const planIcons: { [key in ETokenPlan]: string } = {
      [ETokenPlan.Popular]: '🔥',
      [ETokenPlan.Basic]: '🌟',
      [ETokenPlan.Starter]: '⭐️',
      [ETokenPlan.Premium]: '💎'
    };

    return planIcons[planName] || '';
  }
}
